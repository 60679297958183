<template>
    <div>
        <van-field
            readonly
            :value="value"
            :name="this.name"
            :label="this.label"
            :placeholder="this.placeholder"
            :left-icon="this.left_icon"
            @touchstart.native.stop="show = true"
            :border="false"
        >
            <template #right-icon>
                <span>{{right_icon}}</span>
            </template>
        </van-field>

        <van-number-keyboard
            v-model="value"
            :show="show"
            theme="custom"
            :extra-key="this.extra_key"
            close-button-text="完成"
            @blur="show = false"
        />
    </div>
</template>

<script>
export default {
    name:'NumberKeyboardComponent',
    data(){
        return {
            value: '',
            show: false
        }
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        name: {
            type: String,
            default() {
                return ''
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        placeholder: {
            type: String,
            default() {
                return ''
            }
        },
        rules: {
            type: Boolean,
            default() {
                return true
            }
        },
        extra_key: {
            type: String,
            default() {
                return '00'
            }
        },
        fvalue: {
            type: String|Number,
            default() {
                return ''
            }
        },
        left_icon: {
            type: String,
            default() {
                return ''
            }
        },
        right_icon: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created(){
        this.value = this.fvalue===null ? '' : this.fvalue+''
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        value(e) {
            this.$emit('on_input',e)
        }
    }
}
</script>

<style>
</style>